import React from 'react';
import Layout from '../components/layout/Layout';
import {Helmet} from "react-helmet";
import {graphql, Link} from "gatsby";

const NotFound = ({data}) => (
  <Layout>
      <Helmet>
          <title>{data.strapiCompanyData.name} | Nicht gefunden</title>
      </Helmet>
      <section className="shadow-lg bg-static-sites text-white px-5 rounded">
          <div className="container mx-auto pb-3">
              <h2 className="text-4xl">Die Webseite die Sie suchen wurde leider nicht gefunden. <span role="img" aria-label={"trauriges Gesicht"}>😞</span></h2>
              <Link className="text-white hover:text-white hover:underline" to="/">zurück zur Startseite</Link>
          </div>
      </section>
  </Layout>
);
export const query = graphql`
query NotFound {
  strapiCompanyData {
    name
  }
}
`
export default NotFound;
